import { Col, Modal, Row } from "antd";
import imgDocument from "../../assets/document_download.svg";
import imgTickRight from "../../assets/tickRight.svg";
import imgLoading from "../../assets/loading.svg";
import wrong from "../../assets/wrong.svg";
import "./UploadedDocument.scss";

interface IUploadedDocument {
  showUploadedDocument: boolean;
  setShowUploadedDocument: (value: React.SetStateAction<boolean>) => void;
  uploadDocumentList: any;
  setUploadDocumentList: (value: any) => void;
}

const UploadedDocument: React.FC<IUploadedDocument> = (
  props: IUploadedDocument
) => {
  const {
    showUploadedDocument,
    setShowUploadedDocument,
    uploadDocumentList,
    setUploadDocumentList,
  } = props;

  return (
    <>
      <Modal
        className="model-document-upload"
        open={showUploadedDocument}
        footer={null}
        onCancel={() => {
          setShowUploadedDocument(false);
          setUploadDocumentList({ sucess: [], failed: [] });
        }}
        mask={false}
      >
        <Row className="header">
          <Col span={24} className="header-title">
            Uploaded {uploadDocumentList.sucess.length} out of{" "}
            {Number(uploadDocumentList.sucess.length) +
              Number(uploadDocumentList.failed.length)}{" "}
            Files
          </Col>
        </Row>
        {uploadDocumentList?.sucess?.map((items: any, index: any) => {
          return (
            <Row className="document-content" key={`doc_${index}`}>
              <Col span={2} className="doc-cols">
                <img src={imgDocument} />
              </Col>
              <Col span={13} className="doc-cols">
                <span className="document-name">{items?.name}</span>
              </Col>
              <Col span={6} className="doc-cols">
                <div className="document-size">{(items?.size/1024).toFixed(2)}KB</div>
              </Col>
              <Col span={3} className="doc-cols">
                {items.status === "Done" ? (
                  <img src={imgTickRight} height={30} width={30} />
                ) : (
                  <img
                    className="loading-img"
                    src={imgLoading}
                    height={30}
                    width={30}
                  />
                )}
              </Col>
            </Row>
          );
        })}
        {uploadDocumentList?.failed?.map((items: any, index: any) => {
          return (
            <Row className="document-content" key={`doc_${index}`}>
              <Col span={2} className="doc-cols">
                <img src={imgDocument} />
              </Col>
              <Col span={13} className="doc-cols">
                <span className="document-name">{items?.name}</span>
              </Col>
              <Col span={6} className="doc-cols">
                <div className="document-size">{(items?.size/1024).toFixed(2)}KB</div>
              </Col>
              <Col span={3} className="doc-cols">
                <img src={wrong} height={30} width={30} />
              </Col>
            </Row>
          );
        })}
      </Modal>
    </>
  );
};

export default UploadedDocument;
