import { Button, Col, Modal, notification, Row, Spin, Tooltip } from "antd";
import { ReactComponent as IconUpload } from "../../assets/upload-border.svg";
import imgDocument from "../../assets/document_download.svg";
import imgTickRight from "../../assets/tickRight.svg";
import imgLoading from "../../assets/loading.svg";
import wrong from "../../assets/wrong.svg";
import { ReactComponent as IconLock } from "../../assets/lock_border.svg";
import BSAInputItem from "./BSAInputItem";
import { useEffect, useState } from "react";
import "./BSAInput.scss";
import { InfoCircleOutlined } from "@ant-design/icons";
import API_SERVICE from "../../services/api-service";
import { addDocumentCommon, isRazorPay } from "../../services/Utility";
import AppUpload from "../upload-documents/app-upload";
import { stages } from "../complementor-widget/ComplementorWidget";
import FinboxStatement from "./FinboxStatement";
import UploadedDocument from "./UploadedDocument";

interface IBSAInput {
  leadDetail?: any;
  selectedStage: number;
  setSelectedStage: Function;
  setSelectedStageName: React.Dispatch<React.SetStateAction<stages>>;
  setIsBack: Function;
  clickedSubmitTask: Function;
  taskDefinitionKey: string;
  getPreviousTask: Function;
  addGTag: Function;
  isV2: boolean;
  complementorUserId?: any;
  isMobile: boolean;
  bsaConfiguration: any;
}

const BankStatementV2: React.FC<IBSAInput> = (props: IBSAInput) => {
  const {
    selectedStage,
    setSelectedStage,
    isV2,
    leadDetail,
    setSelectedStageName,
    setIsBack,
    clickedSubmitTask,
    taskDefinitionKey,
    getPreviousTask,
    addGTag,
    complementorUserId,
    isMobile,
    bsaConfiguration,
  } = props;

  const [loading, setLoading] = useState(false);
  const [fileSize, setFileSize] = useState(false);
  const [uploadDocumentList, setUploadDocumentList] = useState({
    sucess: [],
    failed: [],
  } as any);
  const [popupVisible, setPopupVisible] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null as any);
  const [selectedDocs, setSelectedDocs] = useState([] as any);
  const [documentList, setDocumentList] = useState([] as any);
  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmType, setConfirmType] = useState(
    "ADD_FILE" as "ADD_FILE" | "RUN_ANALYSIS"
  );
  const [bankList, setBankList] = useState([]);
  const [isFinBox, setIsFinBox] = useState(false);
  const [showMsgAnalysis, setShowMsgAnalysis] = useState(false);
  const [msgAnalysis, setMsgAnalysis] = useState("");
  const [finBoxEntityId, setFinBoxEntityId] = useState("");
  
  const isFinbox = !["user_task_upload_bank_statements", "documentation_user_task"].includes(taskDefinitionKey)
  const bsaProvider = (!isFinbox ? "DISABLED" : (bsaConfiguration?.bsaProvider ?? "VMT")) as any;

  const documentSetId = leadDetail?.caseDetails?.documentSetId;
  const noOfMonths = leadDetail?.masterProductActionConfigurations?.find(
    (con: any) => con?.actionId == "BANK_STATEMENT_ANALYSIS"
  )?.noOfMonths;

  useEffect(() => {
    if (bsaProvider == "DISABLED" && isFinbox) {
      notification.error({
        message:
          "The Bank Statement Analysis feature is currently disabled. Contact the administration team to enable it",
      });
    } else {
      if (bsaProvider == "FINBOX") {
        getShowFinboxStatus();
        // un comment when open from vas action
      }
    }
    if (bsaProvider != "FINBOX") {
      // getDocumentList(false);
      getDocumentBankData();
    }
  }, []);

  const getDocumentList = (onlyListing: boolean) => {
    setLoading(true);
    API_SERVICE.getBSADocumentList(leadDetail?.caseDetails?.caseId, onlyListing)
      .then(({ data }) => {
        if (data) {
          const docCatFilter = data?.payload?.documentConfigDetails?.filter(
            (docCat: any) => ["Bank Statements", "BANK_STATEMENT"].includes(docCat?.documentCategoryName)
          );
          const docList = docCatFilter
            ?.at(0)
            ?.documentTypeWithUploadedDetails?.filter(
              (doc: any) =>
                doc?.documentTypeType == "BANK_STATEMENT" &&
                doc?.documentDetails
            );
          const sortedList = docList.sort((a, b) =>
            a.documentDetails.documentDate > b.documentDetails.documentDate ? 1 : -1
          );
          setDocumentList(sortedList ?? []);
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
      .finally(() => setLoading(false));
  };

  const getDocumentBankData = () => {
    API_SERVICE.getBSAListBanks({isValidForAnalysis: bsaProvider == "VMT"})
      .then(({ data }) => {
        if (data?.payload) {
          const updtedData: any = [];
          data?.payload?.banks?.filter((item: any) => {
            updtedData.push({
              label: item.name,
              value: item.code,
            });
          });
          setBankList(updtedData);
        }
      })
      .catch((e) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
      .finally(() => {
        getDocumentList(false);
      });
  };

  const getShowFinboxStatus = () => {
    setLoading(true);
    setIsFinBox(true);
    API_SERVICE.getShowFinboxStatus()
      .then(({ data }) => {
        if (data?.payload) {
          setIsFinBox(true);
        } else {
          getAnalysisCheck();
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
      .finally(() => setLoading(false));
  };

  const getAnalysisCheck = () => {
    setLoading(true);
    API_SERVICE.getAnalysisCheck(leadDetail?.caseDetails?.caseId)
      .then(({ data }) => {
        if ((data?.payload?.disclaimerMessage?.length ?? 0) > 0) {
          setShowMsgAnalysis(true);
          setMsgAnalysis(data?.payload?.disclaimerMessage);
        } else {
          setLoading(false);
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
        setLoading(false);
      });
  };

  const uploadDocumtnt = async (file, data) => {
    setLoading(true)
    const fileSize = file?.size ? (file?.size / 1024 / 1024).toFixed(2) : null;
    const response = await addDocumentCommon(
      documentSetId,
      data,
      file.name,
      "BANK_STATEMENT",
      "BANK_STATEMENT",
      fileSize,
    );
    setLoading(false)
    setSelectedDocs([]);
    getDocumentList(true);
  };

  const bsaAnalyse = () => {
    setLoading(true);
    const params = {
      caseId: leadDetail?.caseDetails?.caseId,
      businessDocumentIds: selectedDocs,
      numberOfMonthsForAnalysis: noOfMonths,
      platformSource: "COMPLEMENTOR_WIDGET",
    };
    API_SERVICE.bsaAnalyse(params)
      .then(({ data }) => {
        notification.success({ message: data?.message });
        setSelectedStage(selectedStage + 1);
        if (
          leadDetail?.loanType != "Home Loan" &&
          (leadDetail?.applicationSubType === "SALARIED" ||
            leadDetail?.loanType === "Personal Loan")
          // || !API_BASE_URL.includes("uat")
        ) {
          setSelectedStageName("offer");
        } else {
          clickedSubmitTask({});
        }
        addGTag("upload document");
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
      .finally(() => setLoading(false));
  };

  const renderAppUload = () => {
    // if (bsaProvider == "DISABLED") {
    //   return (
    //     <Button className="app-btn-secondary-border BSA-disable">
    //       <IconUpload />
    //       Upload
    //     </Button>
    //   );
    // } else 
    if (bsaProvider == "FINBOX") {
      return (
        <Button
          className="app-btn-secondary-border"
          onClick={() => {
            getShowFinboxStatus();
          }}
        >
          <IconUpload />
          Upload
        </Button>
      );
    }

    return (
      <AppUpload
        showLoader
        acceptType={["pdf"]}
        showUploadList={false}
        multiple
        maxSize={10}
        setPopupVisible={setPopupVisible}
        // popupVisible={popupVisible}
        uploadDocumentList={uploadDocumentList}
        // setUploadDocumentList={setUploadDocumentList}
        callBack={(response, uploadDocumentList) => {
          if (response?.data && response?.file) {
            setUploadedFile(response?.file);
            uploadDocumtnt(response?.file, response?.data);
          }
        }}
        className=""
        setFileSize={setFileSize}
        setUploadedFile={setUploadedFile}
        setIsSelectedLenderDoc={undefined}
      >
        <Button className="app-btn-secondary-border">
          <IconUpload />
          Upload
        </Button>
      </AppUpload>
    );
  };

  const renderConfirm = () => {
    return (
      <Modal
        visible={showConfirm}
        closable={false}
        footer={null}
        width={400}
        centered
      >
        <div className="delete-modal">
          <span>
            {confirmType == "ADD_FILE"
              ? "Do you want to add more files to your analysis?"
              : `Recommended months for analysis are ${noOfMonths}. Are you sure you want to run the analysis with these files?`}
          </span>
          <div className="delete-modal-btn">
            <Button
              className="app-btn-border"
              style={
                {
                  "--btnColor":
                    confirmType == "ADD_FILE" ? "#c71c8e" : "#273896",
                } as any
              }
              onClick={() => {
                if (bsaProvider != "VMT") {
                  setShowConfirm(false);
                  setSelectedStage(selectedStage + 1);
                  if (
                    leadDetail?.loanType != "Home Loan" &&
                    (leadDetail?.applicationSubType === "SALARIED" ||
                      leadDetail?.loanType === "Personal Loan")
                  ) {
                    setSelectedStageName("offer");
                  } else {
                    clickedSubmitTask({});
                  }
                  addGTag("upload document");
                } else if (confirmType == "ADD_FILE" && noOfMonths > 0) {
                  setConfirmType("RUN_ANALYSIS");
                } else {
                  bsaAnalyse();
                  setShowConfirm(false);
                }
              }}
            >
              {confirmType == "ADD_FILE" ? "No" : "Yes"}
            </Button>
            <Button
              className="app-btn-primary-bg"
              onClick={() => setShowConfirm(false)}
            >
              Add More Files
            </Button>
          </div>
        </div>
      </Modal>
    );
  };

  const renderDocuments = () => {
    return (
      <>
        {!isMobile && (
          <div className="BSA-II-document BSA-II-document-header">
            <div className="BSA-II-file-name" style={{ display: "flex" }}>
              File name
            </div>
            <div className="BSA-II-format">
              Format
              <Tooltip title="The document should be a native PDF">
                <InfoCircleOutlined />
              </Tooltip>
            </div>
            <div className="BSA-II-maxsize">
              File size
              <Tooltip title="The file size should be less than 10MB">
                <InfoCircleOutlined />
              </Tooltip>
            </div>
          </div>
        )}
        {documentList?.map((doc: any, index: number) => {
          return (
            <BSAInputItem
              key={`doc-${doc?.documentDetails?.businessDocumentId}`}
              document={doc}
              setSelectedDocs={setSelectedDocs}
              selectedDocs={selectedDocs}
              bankList={bankList}
              callBackDelete={() => {
                getDocumentList(true);
              }}
              setLoading={setLoading}
              isMobile={isMobile}
              caseId={leadDetail?.caseDetails?.caseId}
              bsaConfiguration={bsaConfiguration}
              taskDefinitionKey={taskDefinitionKey}
            />
          );
        })}
      </>
    );
  };

  const renderBankStatement = () => (
    <FinboxStatement
      setIsFinBox={setIsFinBox}
      leadData={leadDetail}
      isFinBox={isFinBox}
      setIsLoading={setLoading}
      finBoxEntityId={finBoxEntityId}
      setFinBoxEntityId={setFinBoxEntityId}
      getDocumentList={() => {
        setLoading(false);
        // getDocumentList(true)
      }}
    />
  );

  const renderVASActionModal = () => {
    return (
      <Modal
        title=""
        centered
        width={400}
        closable={false}
        footer={null}
        visible={showMsgAnalysis}
      >
        <div className="p-10">
          <div style={{ textAlign: "center" }}>{msgAnalysis}</div>
          <div style={{
            display: "flex",
            justifyContent: "center",
            padding: "10px",
          }} >
            <Button
              className="dynamic-btn-primary"
              size="large"
              type="primary"
              onClick={() => setShowMsgAnalysis(false)}
            >
              OK
            </Button>
          </div>
        </div>
      </Modal>
    );
  };

  return (
    <div style={{ marginInline: "50px" }}>
      {isFinBox && renderBankStatement()}
      {showMsgAnalysis && renderVASActionModal()}
      {renderConfirm()}
      <UploadedDocument
        showUploadedDocument={popupVisible}
        setShowUploadedDocument={setPopupVisible}
        uploadDocumentList={uploadDocumentList}
        setUploadDocumentList={setUploadDocumentList}
      />
      <div className="BSA-II-header">
        <div className="BSA-header-title">
          <span>
            Bank Statement Analysis
          </span>
          {noOfMonths > 0 &&
            <span
              className={`BSA-doc-month-req`}
            >
              {`${noOfMonths} Months Statement Required`}
            </span>
        }
          {/* {bsaProvider == "DISABLED" && (
            <IconLock
              onClick={() => {
                notification.error({
                  message:
                    "The Bank Statement Analysis feature is currently disabled. Contact the administration team to enable it",
                });
              }}
            />
          )} */}
        </div>
        {renderAppUload()}
      </div>
      {bsaProvider != "FINBOX" && renderDocuments()}
      {bsaProvider == "FINBOX" && 
        <div style={{fontWeight:'300', marginBlock:'30px', textAlign:'center'}}>
          Please click on “Upload Button” to initiate the Bank statement Analyzer widget
        </div>
      }
      <div className="footer-btn">
        <Button
          className="app-btn-secondary-border"
          onClick={() => {
            if (
              leadDetail?.applicationSubType === "SALARIED" ||
              leadDetail?.loanType === "Personal Loan"
              // || !API_BASE_URL.includes("uat")
            ) {
              setIsBack(true);
              setSelectedStage(selectedStage - 1);
              setSelectedStageName("loanDetail");
            } else {
              getPreviousTask({ loanType: leadDetail?.loanType });
            }
          }}
        >
          Back
        </Button>
        <Button
          className="app-btn-primary-bg"
          disabled={(selectedDocs?.length ?? 0) == 0 && bsaProvider == "VMT"}
          onClick={() => {
            if(bsaProvider == "VMT") {
              setConfirmType("ADD_FILE");
              setShowConfirm(true);
            } else {
              setShowConfirm(false);
              setSelectedStage(selectedStage + 1);
              if (
                leadDetail?.loanType != "Home Loan" &&
                (leadDetail?.applicationSubType === "SALARIED" ||
                  leadDetail?.loanType === "Personal Loan")
              ) {
                setSelectedStageName("offer");
              } else {
                clickedSubmitTask({});
              }
              addGTag("upload document");
            }
          }}
        >
          Continue
        </Button>
      </div>
      {loading && (
        <div
          className={
            "loader-view" + (isRazorPay(complementorUserId) ? " razorpay" : "")
          }
        >
          <Spin
            tip="We are processing your request, please wait"
            size="large"
          />
        </div>
      )}
    </div>
  );
};

export default BankStatementV2;
