import { notification } from "antd";
import API_SERVICE from "./api-service";

export const isRazorPay = (complementorUserId: any) => {
  return ['CONN4656904990', 'CONN3003750473', 'CONN9734857901'].includes(complementorUserId);
}

export const base64ToArrayBuffer = (base64: string) => {
  var binaryString = window.atob(base64);
  var binaryLen = binaryString.length;
  var bytes = new Uint8Array(binaryLen);
  for (var i = 0; i < binaryLen; i++) {
    var ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes;
};

export const saveByteArray = (function () {
  var a = document.createElement("a") as any;
  document.body.appendChild(a);
  a.style = "display: none";
  return function (data: BlobPart[] | undefined, name: any) {
    var blob = new Blob(data, { type: "octet/stream" }),
      url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = name;
    a.click();
    window.URL.revokeObjectURL(url);
  };
})();

export const downloadDocument = (businessId: String, documentId: String) => {
  API_SERVICE.downloadDocument(businessId, documentId)
    .then(({ data }) => {
      console.log(data);
      let bytesData = base64ToArrayBuffer(data.payload.documentDetails);
      saveByteArray([bytesData], data.payload.documentName);
    })
    .catch((e: any) => {
      notification.error({ message: API_SERVICE.handleErrors(e) });
    });
};

export const updateDropOfStage = (stage: any) => {
  const prospectId = localStorage.getItem("prospectId");
  // const prospectId = JSON.parse(user as string).prospectId;
  API_SERVICE.updateDropOfStage(prospectId, stage)
    .then(({ data }) => {})
    .catch(function (e) {})
    .finally(() => {});
};

export  const updateDropOfStageBuyLead = (stage: string) => {
  const userStr = localStorage.getItem("user");
  const user = JSON.parse(userStr as string);
  API_SERVICE.updateTransaction(user.buyLeadsTransactionId, { dropOffStage: stage})
}

export const numberWithCommas = (x: any) => {
  return x?.toString().split(".")[0].length > 3
    ? x?.toString()
      .substring(0, x?.toString().split(".")[0].length - 3)
      .replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
    "," +
    x?.toString().substring(x?.toString().split(".")[0].length - 3)
    : x?.toString();
};

export const addDocumentCommon = async (
  documentSetId: string,
  url: string,
  name: string,
  type: string,
  subtype: string,
  fileSize?: string | null
) => {
  const payload = {
    documentURL: url ?? "",
    documentName: name ?? "",
    documentType: type ?? "",
    documentSubType: subtype ?? "",
    fileSize: fileSize,
  };

  try {
    const { data } = await API_SERVICE.addDocument(payload, documentSetId);
    return { payload: data?.payload };
  } catch (e) {
    notification.error({ message: API_SERVICE.handleErrors(e) });
    return { payload: null };
  }
};

export const fileUploadCommon = async (file: any) => {
  const formData = new FormData();
  formData.append("file", file?.originFileObj ?? file);
  try {
    const { data } = await API_SERVICE.fileUpload(formData);
    return { file, data, index: null };
  } catch (e) {
    notification.error({ message: API_SERVICE.handleErrors(e) });
    return { file, data: null, index: null };
  }
};

export const getEnumList = (propertyName: string) => {
  let enumValues = localStorage.getItem("enumValues") as any;
  if (enumValues) {
    enumValues = JSON.parse(enumValues);
    const enumValue = enumValues.find(
      (item) => item.propertyName === propertyName
    );
    return enumValue?.keyAndDisplayValueList;
  }
  return [];
};

