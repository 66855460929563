import React from 'react';
import { Typography, Space } from 'antd';
import errorImage from '../../assets/404.svg'
import './NotFound.scss'
const { Title, Text } = Typography;

const NotFound: React.FunctionComponent<any> = (props: any) => {
  return (
    <div className="not-found-container">
      {/* 404 with shadow */}
      <img src={errorImage}   className="error-image" />

      {/* Error with border */}
      <div className="error-card">
        ERROR
      </div>

      <Typography className="additional-info" style={{textAlign:'center'}}>
       
          <Title level={3}style={{ margin: 0 }}>You have not opted for this journey.</Title>
          <Title level={3}style={{ margin: 0 }}>Please reach out to the support team to enable access.</Title>
        
      </Typography>
    </div>
  );
}

export default NotFound;
