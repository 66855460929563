import {
  Button,
  Checkbox,
  Form,
  Input,
  Modal,
  notification,
  Select,
  Tooltip,
} from "antd";
import { ReactComponent as IconDelete } from "../../assets/delete.svg";
import { ReactComponent as IconDown } from "../../assets/down.svg";
import iconError from "../../assets/error.svg";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import "./BSAInput.scss";
import API_SERVICE from "../../services/api-service";

interface IBSAInputItem {
  selectedDocs: any;
  setSelectedDocs: Dispatch<any>;
  document: any;
  bankList: any;
  callBackDelete: any;
  setLoading: Dispatch<SetStateAction<boolean>>;
  isMobile: boolean;
  caseId: string;
  bsaConfiguration: any;
  taskDefinitionKey: string;
}

const BSAInputItem: React.FC<IBSAInputItem> = (props: IBSAInputItem) => {
  const {
    selectedDocs,
    setSelectedDocs,
    document,
    bankList,
    callBackDelete,
    setLoading,
    isMobile,
    caseId,
    bsaConfiguration,
    taskDefinitionKey,
  } = props;

  const [showDelete, setShowDelete] = useState(false);
  const [isEditable, setIsEditable] = useState(true);
  const [collapseStatement, setCollapseStatement] = useState(true);
  const [isFilePassword, setIsFilePassword] = useState(false);
  const [isBankNotMatch, setIsBankNotMatch] = useState(false);

  const isFinbox = !["user_task_upload_bank_statements", "documentation_user_task"].includes(taskDefinitionKey)
  const bsaProvider = (!isFinbox ? "DISABLED" : (bsaConfiguration?.bsaProvider ?? "VMT")) as any;

  useEffect(() => {
    setIsEditable((docAdditionalInfo?.bankId?.length ?? 0) == 0);
    setIsFilePassword((docAdditionalInfo?.filePassword?.length ?? 0) > 0);
    if (docAdditionalInfo?.bankId) {
      const find = bankList?.some(
        (bank: any) => bank.value == docAdditionalInfo?.bankId
      );
      setIsBankNotMatch(!find);
    }
  }, []);

  const onFinish = (values) => {
    if ((docAdditionalInfo?.bankId?.length ?? 0) > 0 && !isEditable) {
      setIsEditable(true);
      return;
    }

    const payload = {
      caseId: caseId,
      source: bsaProvider == "VMT" ? "INPUT_SCREEN" : null,
      documentAdditionalInfo: {
        documentApplicantType: document?.documentDetails?.documentApplicantType,
        docOrder: document?.documentDetails?.docOrder,
        fileSize: document?.documentDetails?.fileSize,
        documentTypeId: document?.documentTypeId,
        bankId: values?.bankName,
        filePassword: values?.isPassword === "NO" ? null : values?.password,
      },
    };
    const businessID = document?.documentDetails?.businessDocumentId;
    setLoading(true);
    API_SERVICE.updateDocumentBankDetail(businessID, payload)
      .then(({ data }) => {
        if (data?.payload) {
          document.documentDetails = data?.payload;
          setIsEditable(false);
          setSelectedDocs([]);
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
      .finally(() => setLoading(false));
  };

  const actionDelete = () => {
    setLoading(true);
    API_SERVICE.deleteDocument(
      document.documentDetails.businessId,
      document.documentDetails.businessDocumentId
    )
      .then(({ data }) => {
        callBackDelete();
      })
      .catch((e: any) => {
        setLoading(false);
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const renderDelete = () => {
    return (
      <Modal
        open={showDelete}
        closable={false}
        footer={null}
        width={400}
        centered
      >
        <div className="delete-modal">
          <span>This file will get deleted from your uploaded documents.</span>
          <span>Are you sure you want to delete?</span>
          <div className="delete-modal-btn">
            <Button
              className="app-btn-secondary-border"
              onClick={() => setShowDelete(false)}
            >
              No
            </Button>
            <Button
              className="app-btn-primary-bg"
              onClick={() => {
                actionDelete();
                setShowDelete(false);
              }}
            >
              Yes
            </Button>
          </div>
        </div>
      </Modal>
    );
  };

  const docAdditionalInfo =
    document?.documentDetails?.documentAdditionalInfoDto;
    const bsaDetail = docAdditionalInfo?.bsaDetails?.at(0)?.details?.at(0)
    const errors = bsaDetail?.errors;
    const filePath = bsaDetail?.filePath;
    const timeDetails = bsaDetail?.timeDetails;
    const statementDataTmp =
    collapseStatement && (timeDetails?.length ?? 0) > 0
      ? [timeDetails?.at(0)]
      : timeDetails;
  return (
    <div>
      {renderDelete()}
      <div className={`BSA-input-item ${errors?.lenght > 0  ? "BSA-error" : ""}`}>
        <div className="BSA-II-document">
          <div className="BSA-II-file-name" style={{ display: "flex" }}>
            {(filePath?.length ?? 0) > 0  && bsaProvider == "VMT" && (
              <Checkbox
                disabled={isBankNotMatch}
                checked={selectedDocs?.includes(document?.documentDetails?.businessDocumentId)}
                onChange={(value) => {
                  if (value.target.checked) {
                    setSelectedDocs([
                      ...selectedDocs,
                      document?.documentDetails?.businessDocumentId,
                    ]);
                  } else {
                    const filter = selectedDocs?.filter(
                      (id) =>
                        id != document?.documentDetails?.businessDocumentId
                    );
                    setSelectedDocs(filter);
                  }
                }}
              />
            )}
            <span>
              {isMobile ? "File Name: " : ""}
              {document?.documentDetails?.documentName}
            </span>
            {isMobile && (
               <div>
               {errors?.length > 0 &&
                 <Tooltip title={errors?.map((error: any) => error?.message)?.join(", ")}>
                   <img
                     src={iconError}
                     width="20px"
                     height="20px"
                     alt=""
                     style={{ marginTop: "3px" }}
                   />
                 </Tooltip>
               }
                <Button type="text" onClick={() => setShowDelete(true)}>
                  <IconDelete />
                </Button>
              </div>
            )}
          </div>
          <div className="BSA-II-format">
            {isMobile ? (
              <span style={{ marginLeft: "26px" }}>{"Format: "}</span>
            ) : (
              ""
            )}
            {document?.documentDetails?.fileExtn}
          </div>
          <div className="BSA-II-maxsize">
            {isMobile ? (
              <span style={{ marginLeft: "26px" }}>{"File size: "}</span>
            ) : (
              ""
            )}
            {document?.documentDetails?.fileSize ? (document?.documentDetails?.fileSize + "MB") : " - "}
          </div>
          {!isMobile && (
            <div className="BSA-II-action">
              {errors?.length > 0 &&
                <Tooltip title={errors?.map((error: any) => error?.message)?.join(", ")}>
                  <img
                    src={iconError}
                    width="20px"
                    height="20px"
                    alt=""
                    style={{ marginTop: "8px" }}
                  />
                </Tooltip>
              }
              <Button type="text" onClick={() => setShowDelete(true)}>
                <IconDelete />
              </Button>
            </div>
          )}
        </div>
        {(statementDataTmp?.length ?? 0) > 0 && (
          <div className="BSA-II-statements">
            <span>Statement data:</span>
            <div className="BSA-II-statement-data">
              {statementDataTmp?.map((statement: any) => {
                return (
                  <div className="BSA-II-statements-details">
                    <span>{`${statement?.year}: `}</span>
                    <div className="BSA-II-statements-months">
                      {statement?.monthsData?.map((month) => {
                        return (
                          <span
                            className={`BSA-II-statements-month ${
                              month?.verify ? "month-verify" : ""
                            }`}
                          >
                            {month?.month}
                          </span>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
            <Button
              type="text"
              onClick={() => setCollapseStatement(!collapseStatement)}
            >
              <IconDown
                className={!collapseStatement ? "collapse-data-icon" : ""}
              />
            </Button>
          </div>
        )}
        <Form
          className="BSA-II-document BSA-II-document-bank"
          name={`doc-${document?.documentDetails?.businessDocumentId}`}
          onFinish={onFinish}
          initialValues={{
            bankName: docAdditionalInfo?.bankId,
            isPassword:
              (docAdditionalInfo?.filePassword?.length ?? 0) > 0
                ? "YES"
                : (docAdditionalInfo?.bankId?.length ?? 0) > 0
                ? "NO"
                : null,
            password: docAdditionalInfo?.filePassword,
          }}
        >
          <div className="BSA-II-file-name" style={{ display: "grid" }}>
            <Form.Item
              name={"bankName"}
              rules={[
                {
                  required: true,
                  message: "Please select Bank Name",
                },
              ]}
            >
              <Select
                placeholder="Bank Name"
                options={bankList}
                showSearch
                disabled={!isEditable}
                filterOption={(input, option) =>
                  (option?.label?.toString() ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                onChange={() => setIsBankNotMatch(false)}
              />
            </Form.Item>
            {isBankNotMatch && (
              <span
                style={{ color: "red", fontSize: "14px", marginTop: "-10px" }}
              >
                Bank not valid for processing
              </span>
            )}
          </div>
          <div className="BSA-II-format">
            <Form.Item
              name={"isPassword"}
              rules={[
                {
                  required: true,
                  message: "Please select Is file password protected?",
                },
              ]}
            >
              <Select
                placeholder="Is file password protected?"
                disabled={!isEditable}
                onSelect={(value) => {
                  setIsFilePassword(value == "YES");
                }}
              >
                <Select.Option key={"YES"}>Yes</Select.Option>
                <Select.Option key={"NO"}>No</Select.Option>
              </Select>
            </Form.Item>
          </div>
          <div className="BSA-II-maxsize">
            {isFilePassword && (
              <Form.Item
                name={"password"}
                rules={[
                  {
                    required: true,
                    message: "Please enter Password",
                  },
                ]}
              >
                <Input.Password placeholder="Password" disabled={!isEditable} />
              </Form.Item>
            )}
          </div>
          <div className="BSA-II-action">
            <Button htmlType="submit" className="app-btn-primary-bg">
              {(docAdditionalInfo?.bankId?.length ?? 0) > 0 && !isEditable
                ? "Edit"
                : "Save"}
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default BSAInputItem;
