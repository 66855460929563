import { Button, Form, Modal, notification, Typography } from "antd";
import React, { useState, useEffect } from "react";
import FormCheckbox from "../../components/FormCheckbox/FormCheckbox";
import FormInput from "../../components/FormInput/FormInput";
import { PHONE_NUMBER_REGEX, STRING_ONLY } from "../../constants/AppConstants";
import API_SERVICE from "../../services/api-service";
import { stages } from "../complementor-widget/ComplementorWidget";
import { useParams } from "react-router-dom";
import { loanTypeList } from "../start/start";
import ReCAPTCHA from 'react-google-recaptcha';
import "./PersionalDetails.scss";
import { getEnumList, isRazorPay } from "../../services/Utility";
import FormSelect from "../../components/FormSelect/FormSelect";
import { get } from "http";

const { Text, Link, Paragraph } = Typography;

interface IPersionalDetails {
  getAppContent: any;
  selectedStage: number;
  setSelectedStage: Function;
  setTmpLoanKey: any;
  tmpLoanKey: string;
  loanType: string;
  complementorUserId: String;
  connectorShortDetails: any;
  utmDetailsDto: any;
  addGTag: Function;
  getNextTask: Function;
  setSelectedStageName: React.Dispatch<React.SetStateAction<stages>>;
  uploadLog: (message: string, params: any) => void;
}

const PersionalDetails: React.FunctionComponent<IPersionalDetails> = (
  props: IPersionalDetails
) => {
  const {
    getAppContent,
    selectedStage,
    setSelectedStage,
    setTmpLoanKey,
    tmpLoanKey,
    loanType,
    complementorUserId,
    connectorShortDetails,
    setSelectedStageName,
    getNextTask,
    utmDetailsDto,
    addGTag,
    uploadLog,
  } = props;

  const [form] = Form.useForm() as any;
  const [loading, setLoading] = useState(false);
  const [isOpenVerify, setIsOpenVerify] = useState(false);
  const [showCaptchaForConfirmOTP, setShowCaptchaForConfirmOTP] = useState(false);
  const [reCaptchaTokenForConfirmMobile, setReCaptchaTokenForConfirmMobile] = useState("");

  const { type } = useParams() as any;

  useEffect(() => {
    form.setFieldsValue({
      firstName: connectorShortDetails?.name ?? "",
      mobile: connectorShortDetails?.mobileNumber ?? "",
    });
  }, [connectorShortDetails]);

  const signUp = (values: any) => {
    setLoading(true);

    let searchParams = new URL(window.location.href).searchParams;
    console.log("PersionalDetails searchParams: ", searchParams);
    const urlParams: any = {};
    searchParams?.forEach((value, key) => {
      urlParams[key] = value;
    });

    console.log("PersionalDetails urlParams: ", urlParams);
    const params = {
      ...values,
      dsaCaseId: connectorShortDetails?.dsaCaseId ?? "",
      processInstanceId: connectorShortDetails?.processInstanceId ?? "",
      overrideSourceConnectorId: urlParams?.sub_connector_id,
      utmDetailsDto: utmDetailsDto
    };

    uploadLog("Signup Api", params);
    console.log("tmp", tmpLoanKey)
    const loanTypeTmp: any =
      (tmpLoanKey?.length ?? 0) > 0 ? tmpLoanKey
        : (loanType?.length ?? 0) > 0
          ? loanType
          : loanTypeList?.find((item: any) => item?.code == type)?.key ??
          "BUSINESS_INSTALLMENT_LOAN";


    API_SERVICE.singUp(loanTypeTmp, complementorUserId, params)
      .then(({ data }) => {
        uploadLog("Signup Api Response", data);
        if (data) {
          const user = {
            mobile: data?.payload?.mobileNumber,
            ...data.payload,
            ...values,
          };
          localStorage.setItem("user", JSON.stringify(user));
          notification.success({ message: data.message });
          setSelectedStageName("otp");
        }
      })
      .catch(function (e) {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const [loginValues, setLoginValues] = useState({} as any);

  function checkMobile(e) {
    if (e.target.value.length === 10) {
      const payload = {
        mobileNumber: e?.target?.value,
        reCaptchaScreenName: "OTP"
      }

      API_SERVICE.checkRecaptchaResponse(payload)
        .then(({ data }) => {
          if (data) {
            setShowCaptchaForConfirmOTP(data?.payload?.captchaRequired);
          }
        })
        .catch(function (e) {
          notification.error({ message: API_SERVICE.handleErrors(e) });
        })
    }
  }

  const onIndividualType = (value: string) => {
    const payload = {
      "individualType": value
    };
    API_SERVICE.updateLoanProductCode(payload)
      .then(({ data }) => {
        console.log('data', data);
        if ((data?.payload?.loanProductShortCode?.length ?? 0) > 0) {
          const loanTypeTmp = loanTypeList?.find((item: any) => item?.code == data?.payload?.loanProductShortCode)?.key;
          setTmpLoanKey(loanTypeTmp);
        } else {
          setTmpLoanKey(null);
        }
        // else {
        //   const loanTypeTmp = loanTypeList?.find((item: any) => item?.code == type)?.key ??
        //     "BUSINESS_INSTALLMENT_LOAN";
        //     setLoanType(loanTypeTmp);
        // }
      }).catch(function (e) {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
  }

  const onFinish = (values: any) => {
    console.log("values", values);
    if ((showCaptchaForConfirmOTP && reCaptchaTokenForConfirmMobile.length === 0)) {
      notification.error({
        message: "Please check reCaptcha",
      });
    } else {
      setIsOpenVerify(true);
      setLoginValues(values);
    }
  };

  const renderCheckboxText = () => {
    return (
      <div style={{ display: "grid" }}>
        <Text>
          I agree to the{" "}
          <Link
            onClick={() => getAppContent("TERMS_AND_CONDITIONS")}
            target="_blank"
          >
            Terms and Conditions
          </Link>
          ,{" "}
          <Link onClick={() => getAppContent("PRIVACY_POLICY")} target="_blank">
            Privacy policy
          </Link>{" "}
          <Text>
            {"and hereby authorise OneInfinity "}
          </Text>
          {isRazorPay(complementorUserId)
            ? <Text style={{ fontWeight: 'bold' }}>{"(Razorpay Capital Partner) "}</Text>
            : <></>
          }
          <Text>
            and/or its representatives to
            call, email, Whats APP, or SMS me with reference to my loan
            application. This consent supersedes any DNC (do not call)
            registration by me.
          </Text>
        </Text>
        {loanType === "PERSONAL_LOAN_ONLY" && (
          <Text>
            You also authorize our lending partners to retrieve your credit
            report & communicate with you via phone, e-mails, WhatsApp, etc.
          </Text>
        )}
      </div>
    );
  };

  const renderCheckboxTextIndiaMart = () => {
    return (
      <div style={{ display: "grid" }}>
        <Text>
          I agree to the{" "}
          <Link
            onClick={() => getAppContent("TERMS_AND_CONDITIONS")}
            target="_blank"
          >
            T & C
          </Link>
          ,{" "}
          <Link onClick={() => getAppContent("PRIVACY_POLICY")} target="_blank">
            Privacy policy
          </Link>{" "}
          and authorise OneInfinity representatives to contact me. This consent
          supersedes any DNC registration by me.
        </Text>
      </div>
    );
  };

  return (
    <>
      <Modal
        className={isRazorPay(complementorUserId) ? 'razor_pay_modal' : ''}
        title={`Please confirm if ${loginValues?.mobile} is the correct contact number for you.`}
        open={isOpenVerify}
        footer={[
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              className={isRazorPay(complementorUserId) ? 'razorPay_btn' : ''}
              key="submit"
              type="primary"
              loading={loading}
              onClick={() => {
                signUp(form.getFieldValue());
                addGTag("signup click");
              }}
              style={{ paddingInline: "30px" }}
            >
              OK
            </Button>
            <Button key="back" onClick={() => setIsOpenVerify(false)} className={isRazorPay(complementorUserId) ? 'razorPay_btn_cancel' : ''}>
              Cancel
            </Button>
          </div>,
        ]}
        width={300}
        centered
        closable={false}
      >
        {/* <Text>Please ensure you entered the correct mobile number</Text> */}
      </Modal>

      <div className={isRazorPay(complementorUserId) ? 'razorPay_main_form ' : ''} style={{ display: "grid", justifyContent: "center" }}>
        <Form
          layout="vertical"
          autoComplete="off"
          name="leadForm"
          style={{ maxWidth: "400px" }}
          className="form-persion"
          form={form}
          onFinish={onFinish}
        >
          {type == "pl" && (
            <FormSelect
              isRequired
              name="individualType"
              label="Applicant Type"
              onSelect={(e: string) => onIndividualType(e)}
              options={getEnumList("INDIVIDUAL_TYPE")} />
          )}
          <FormInput
            className={isRazorPay(complementorUserId) ? 'razorPay_input' : ''}
            name="firstName"
            label="Your Full Name (As per PAN)"
            isRequired
            rules={[
              {
                pattern: STRING_ONLY,
                message: 'No Special Characters "*,),\' etc." Allowed',
              },
            ]}
          />

          <FormInput
            className={isRazorPay(complementorUserId) ? 'razorPay_input' : ''}
            name="mobile"
            label="Mobile Number"
            inputMode="numeric"
            prefix="+91"
            isRequired
            onChange={checkMobile}
            rules={[
              {
                pattern: PHONE_NUMBER_REGEX,
                message: "Please Enter Valid Mobile Number",
              },
            ]}
          />
          <FormCheckbox
            className={isRazorPay(complementorUserId) ? 'razorpay_checkBox' : ''}
            name={"serviceProvider"}
            isRequired
            msgRequired="Terms and Conditions & Privacy policy"
            textElement={
              complementorUserId == "CONN4128778344"
                ? renderCheckboxTextIndiaMart()
                : renderCheckboxText()
            }
          />
          <br />
          {showCaptchaForConfirmOTP &&
            <div className="g-recaptcha">
              <Form.Item name="reCaptchaToken">
                <ReCAPTCHA
                  sitekey="6LctCysqAAAAAFuSCNIHih9gX7V6yZY6SL4NsFWY" // Replace with your site key
                  onChange={(token) => setReCaptchaTokenForConfirmMobile(token)}
                />
              </Form.Item>
            </div>
          }
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            className={"btn-next" + (isRazorPay(complementorUserId) ? ' razorPay_btn' : '')}
            loading={loading}
          >
            Apply for loan
          </Button>
        </Form>
      </div>
    </>
  );
};

export default PersionalDetails;
